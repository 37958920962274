import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Centennial({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="relative">
        <div
          className="formkit-background"
          style={{
            backgroundColor: styles.forElement("top-background").backgroundColor
          }}
        />
        <div
          className="formkit-background"
          style={styles.forElement("top-background")}
        />
        <header className="formkit-container">
          <Elements.Content
            className="formkit-preheader"
            name="preheader"
            defaults={{
              content: "New eBook"
            }}
          />
          <Elements.Heading
            className="formkit-heading-primary"
            name="header"
            defaults={{
              content: "Get my free book now"
            }}
          />
          <Elements.Heading
            className="formkit-heading-secondary formkit-subheader"
            name="subheader"
            tag="h2"
            defaults={{
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque libero, et lacinia lectus."
            }}
          />
        </header>
        <main className="formkit-container formkit-main">
          <Elements.Region className="formkit-content" name="content">
            <Elements.Content
              defaults={{
                content:
                  "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis. Aenean metus sapien, pulvinar quis consequat ac, mollis sit amet ipsum. In lectus enim, fringilla a nunc vel, mattis viverra tortor. Proin neque quam, iaculis sit amet dictum quis, consectetur in augue. Class aptent taciti sociosqu"
              }}
            />
          </Elements.Region>
        </main>
      </div>
      <div className="relative">
        <div
          className="formkit-background"
          style={{
            backgroundColor: styles.forElement("middle-background")
              .backgroundColor
          }}
        />
        <div
          className="formkit-background"
          style={styles.forElement("middle-background")}
        />
        <div className="formkit-card" style={styles.forElement("card")}>
          <div className="formkit-container">
            <Elements.Heading
              className="formkit-heading-secondary formkit-form-header"
              name="form_header"
              tag="h2"
              defaults={{ content: "Sign up Today" }}
            />
            <Elements.Region
              className="formkit-card-content"
              name="form_content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante."
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign up today" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
        </div>
        <Elements.Region
          className="formkit-container formkit-middle-content"
          name="middle_content"
        >
          <Elements.Content
            defaults={{
              content:
                "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante."
            }}
          />
        </Elements.Region>
      </div>
      <div className="relative">
        <div
          className="formkit-background"
          style={{
            backgroundColor: styles.forElement("bottom-background")
              .backgroundColor
          }}
        />
        <div
          className="formkit-background"
          style={styles.forElement("bottom-background")}
        />
        <Elements.Region
          className="formkit-container formkit-bottom-content"
          name="bottom_content"
        >
          <Elements.Content
            defaults={{
              content:
                "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante."
            }}
          />
        </Elements.Region>
        <footer>
          <Elements.BuiltWith
            background="bottom-background"
            image="bottom-background"
          />
        </footer>
      </div>
    </Elements.LandingPage>
  )
}

Centennial.style = "landing_page"
Centennial.categories = ["eBook", "Newsletter", "Product", "Waitlist"]
Centennial.thumbnail = ""
Centennial.preview = "https://demo.ck.page/centennial"
Centennial.fields = [
  {
    name: "first_name",
    label: "Your name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

Centennial.beta = true

export default createTemplate(Centennial, { name: "Centennial" })
